import { useEffect, useState } from "react";
import { Grid, Box, Tooltip } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import {
  userUploadedCSVState,
  userOperationState,
  CRMIDPresentState,
} from "recoil/atoms";
import { Button } from "components/shared/Button";
import { TablePagination } from "components/shared/TablePagination";
import { ImageIcon } from "components/shared/ImageIcon";
import { useTargetV2Context } from "pages/TargetV2/context/targetV2.context";
import { modifyTargetList } from "services/targetV2Service";
import { getUpdateSavedTargetsData } from "services/savedTargetService";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  arrow: {
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    color: "white",
  },
  tooltip: {
    backgroundColor: "white",
    boxShadow: "0px 3px 6px #00000029",
    width: "160px",
    color: "#808486",
    fontSize: "12px",
    fontFamily: "Open Sans Regular",
    padding: "8px",
  },
}));
const CustomPreview = (props: any) => {
  const { t } = useTranslation();
  let classes = useStyles();
  const setIdState = useSetRecoilState(CRMIDPresentState);
  const userUploadedData = useRecoilValue(userUploadedCSVState);
  const userOperation = useRecoilValue(userOperationState);
  const {
    targetListCsvPreviewUrl,
    targetListCsvProcessingUrl,
    targetListName,
    targetListId,
    targetListData,
    targetListType,
    setTargetListCsvPreviewUrl,
    setTargetListCsvProcessingUrl,
    getFormattedURl,
    setVarianceTL,
    setUserInteraction,
    setShowCustomMsg,
    setTargetListData,
  } = useTargetV2Context();
  const [isBayerCrmIdPresent, setIsBayerCrmIdPresent] = useState(true);
  const [totalCount, setTotalCount] = useState<any>(0);
  const [pageNumber, setPageNumber] = useState<any>(1);
  const [currentPageCount, setCurrentPageCount] = useState<any>(0);
  const [totalPages, setTotalPages] = useState<any>(0);
  const [currentData, setCurrentData] = useState<any>([]);
  const [sanitizedData, setSanitizedData] = useState<any>([]);
  const [errorCount, setErrorCount] = useState<any>(0);
  const [errorArray, setErrorArray] = useState<any>([]); //to know the index of errors
  const size = 5;

  // function to set data for the first page in initial load
  const uploadTableDataSetter = (data: any) => {
    let parsedData = data;
    if (parsedData && Array.isArray(parsedData) && parsedData.length > 1) {
      let dataCount = parsedData.length - 1;
      setTotalCount(dataCount);
      setTotalPages(Math.ceil(dataCount / size));
      if (dataCount > size) {
        setCurrentPageCount(5);
        let bodyTable = parsedData.slice(1, 6);
        setCurrentData(bodyTable);
      } else {
        let bodyTable = parsedData.slice(1);
        setCurrentData(bodyTable);
        setCurrentPageCount(dataCount);
      }
    }
  };
  const cleanseData = () => {
    let parsedData = [...userUploadedData];
    if (parsedData && Array.isArray(parsedData) && parsedData.length > 1) {
      const headerData = parsedData[0];
      const isIdPresent = headerData[0] === "BayerCRM_IDValue";
      if (isIdPresent) {
        //proceed for other validation
        let dataBody = parsedData.slice(1);
        let pattern = /^([a-zA-Z0-9]){18}$/;
        let dummyErrorArray: any[] = [];
        dataBody.forEach((item: any) => {
          if (item.length > 0) {
            const text = item[0];
            const isValid = pattern.test(text);
            if (isValid) {
              dummyErrorArray = [...dummyErrorArray, false];
            } else {
              dummyErrorArray = [...dummyErrorArray, true];
            }
          } else {
            dummyErrorArray = [...dummyErrorArray, true];
          }
        });
        setErrorArray(dummyErrorArray);
        // error management logic
        if (dummyErrorArray.includes(true)) {
          //rearrage the data to pop the errors first
          let newErrorArray: any = [];
          let newDataArray: any = [];
          dummyErrorArray.forEach((item: any, index: number) => {
            if (item === true) {
              newErrorArray = [item, ...newErrorArray];
              newDataArray = [dataBody[index], ...newDataArray];
            } else {
              newErrorArray = [...newErrorArray, item];
              newDataArray = [...newDataArray, dataBody[index]];
            }
          });
          const errors = newErrorArray.filter((item: any) => item === true);
          const errorCount = errors.length;
          const errorsCollection: any = newDataArray.slice(0, errorCount);
          const noErrorsCollection: any = newDataArray.slice(errorCount);
          const reversedArray = errorsCollection.reverse();
          const refinedData = reversedArray.concat(noErrorsCollection);
          setErrorArray(newErrorArray);
          setErrorCount(errorsCollection.length);
          setSanitizedData([headerData, ...refinedData]);
          uploadTableDataSetter([headerData, ...refinedData]);
        } else {
          let fitData = [...userUploadedData];
          setErrorArray([]);
          setErrorCount(0);
          setSanitizedData(fitData);
          uploadTableDataSetter(fitData);
        }
        setIdState(true);
      } else {
        setIsBayerCrmIdPresent(false);
        setIdState(false);
      }
    }
  };
  useEffect(() => {
    cleanseData();
  }, [userUploadedData]);
  const prevIconClick = () => {
    let newPage = pageNumber - 1;
    let parsedData = [...sanitizedData];
    let start = size * newPage - 4;
    let end = size * newPage + 1;
    let bodyTable = parsedData.slice(start, end);
    setCurrentData(bodyTable);
    setCurrentPageCount(size);
    setPageNumber(newPage);
  };
  const nextIconClick = () => {
    let newPage = pageNumber + 1;
    let parsedData = [...sanitizedData];
    let start = size * pageNumber + 1;
    let end = size * newPage + 1;
    let bodyTable = parsedData.slice(start, end);
    setCurrentData(bodyTable);
    if (bodyTable && Array.isArray(bodyTable) && bodyTable.length > 1) {
      setCurrentPageCount(bodyTable.length);
    }
    setPageNumber(newPage);
  };
  const getTableHeader = () => {
    let headerArray;
    headerArray = [...userUploadedData[0]];
    const rows =
      Array.isArray(headerArray) && headerArray.length > 0
        ? headerArray.map((item: any, index: number) => {
            if (index === 0) {
              return (
                <TableCell key={index}>
                  <>
                    <Grid className="table-name">{item}</Grid>
                    <Grid className="help-text">
                      {errorCount > 0 && (
                        <Grid className="alert">
                          <Box className="error-count">{errorCount}</Box>
                          <Box className="img-container">
                            <ImageIcon classes="img-alert-no-fill"></ImageIcon>
                          </Box>
                        </Grid>
                      )}
                      <Grid className="mandatory">{t("mandatory")}</Grid>
                    </Grid>
                  </>
                </TableCell>
              );
            } else {
              return <TableCell key={index}>{item}</TableCell>;
            }
          })
        : null;
    return rows;
  };
  const getTableBody = () => {
    const rows =
      currentData && Array.isArray(currentData) && currentData.length > 0
        ? currentData.map((item: any, index: number) => {
            return (
              <TableRow key={index}>
                {item.map((data: any, itemIndex: number) => {
                  if (itemIndex === 0) {
                    if (errorArray[(pageNumber - 1) * size + index] === true) {
                      if (data.length > 18) {
                        return (
                          <TableCell key={itemIndex}>
                            <Tooltip
                              title={data}
                              placement="top"
                              arrow
                              classes={{
                                arrow: classes.arrow,
                                tooltip: classes.tooltip,
                              }}
                              PopperProps={{ keepMounted: true }}
                            >
                              <Grid className="body-error">
                                <Box className="img-alert-container">
                                  <ImageIcon classes="img-alert-no-fill"></ImageIcon>
                                </Box>
                                <Box className="error-data">
                                  {truncateString(data, 18)}
                                </Box>
                              </Grid>
                            </Tooltip>
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell key={itemIndex}>
                            <Grid className="body-error">
                              <Box className="img-alert-container">
                                <ImageIcon classes="img-alert-no-fill"></ImageIcon>
                              </Box>
                              <Box className="error-data">{data}</Box>
                            </Grid>
                          </TableCell>
                        );
                      }
                    } else {
                      return <TableCell key={itemIndex}>{data}</TableCell>;
                    }
                  } else {
                    return <TableCell key={itemIndex}>{data}</TableCell>;
                  }
                })}
              </TableRow>
            );
          })
        : null;
    return rows;
  };

  const proceedToUpload = () => {
    //close the popup
    if (props.onClose) {
      props.onClose();
    }
    //pass data to backend API
    const userPayload = {
      data: sanitizedData,
      operation: userOperation,
      preview_uri: targetListCsvPreviewUrl,
      uri: targetListCsvProcessingUrl,
    };
    modifyTargetList(userPayload)?.then((response: any) => {
      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.uri &&
        response.data.data.preview_uri
      ) {
        setTargetListCsvPreviewUrl(response.data.data.preview_uri);
        setTargetListCsvProcessingUrl(response.data.data.uri);
        getFormattedURl(response.data.data.preview_uri);
        // edit count in the Target list
        const dummyTargetList: any = _.cloneDeep(targetListData);
        if (dummyTargetList[0] && dummyTargetList[0].countHCPS) {
          if (userOperation === "Add") {
            dummyTargetList[0].countHCPS =
              dummyTargetList[0].countHCPS + (sanitizedData.length - 1);
          } else {
            dummyTargetList[0].countHCPS =
              dummyTargetList[0].countHCPS - (sanitizedData.length - 1);
          }
        }
        //call to persist the new info in middleware.
        const data = {
          targetId: targetListId,
          campaignName: targetListName,
          targetType: targetListType,
          targetData: dummyTargetList[0],
          previewUrl: response.data.data.preview_uri,
          processingUrl: response.data.data.uri,
          varianceTL: sanitizedData.length - 1,
          userInteraction: userOperation,
        };
        getUpdateSavedTargetsData(data)?.then((response: any) => {
          if (response) {
            if (props.onSuccess) {
              setShowCustomMsg(true);
              setVarianceTL(sanitizedData.length - 1);
              setUserInteraction(userOperation);
              setTargetListData(dummyTargetList);
              props.onSuccess(sanitizedData.length - 1);
            }
          }
        });
      }
    });
  };
  const retryUpload = () => {
    if (props.onRetry) {
      props.onRetry();
    }
  };
  const truncateString = (str: any, maxLength: any) => {
    if (str.length > maxLength) {
      return str.slice(0, maxLength - 3) + "...";
    }
    return str;
  };
  return (
    <>
      {userUploadedData &&
        Array.isArray(userUploadedData) &&
        userUploadedData.length > 1 &&
        isBayerCrmIdPresent && (
          <>
            <Grid item xs={12} className="top-grid custom">
              <Box className="hcps">{`${totalCount}`} HCPs</Box>
              <Box className="btns-wrapper">
                <div className="pagination-wrapper">
                  <TablePagination
                    pageNumber={pageNumber}
                    size={size}
                    totalCount={totalCount}
                    currentPageCount={currentPageCount}
                    totalPages={totalPages}
                    prevIconClick={prevIconClick}
                    nextIconClick={nextIconClick}
                  />
                </div>
              </Box>
            </Grid>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>{getTableHeader()}</TableRow>
                </TableHead>
                <TableBody>{getTableBody()}</TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      {!(
        userUploadedData &&
        Array.isArray(userUploadedData) &&
        userUploadedData.length > 1
      ) && <Box className="no-data-preview">{t("no_data_available")}</Box>}
      {userUploadedData &&
        Array.isArray(userUploadedData) &&
        userUploadedData.length > 1 &&
        !isBayerCrmIdPresent && (
          <Box className="no-data-preview">
            {t("BayerCRM_IDValue_mandatory")}
          </Box>
        )}
      <Grid
        className={`popup-footer-container ${
          !(
            userUploadedData &&
            Array.isArray(userUploadedData) &&
            userUploadedData.length > 1
          )
            ? "no-items"
            : ""
        }`}
      >
        {userUploadedData &&
          Array.isArray(userUploadedData) &&
          userUploadedData.length > 1 && (
            <Box
              display="flex"
              justifyContent="flex-end"
              gap="25px"
              alignItems="center"
              onClick={
                errorCount > 0 || !isBayerCrmIdPresent
                  ? retryUpload
                  : proceedToUpload
              }
            >
              <Button type="small" variant="secondary">
                {errorCount > 0 || !isBayerCrmIdPresent
                  ? t("retry")
                  : userOperation === "Add"
                  ? t("add_HCPs")
                  : t("remove_HCPs")}
              </Button>
            </Box>
          )}
      </Grid>
    </>
  );
};
export default CustomPreview;
